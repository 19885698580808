import React, { Component } from "react";
import { Alert, SpaceBetween } from "@amzn/awsui-components-react";
import Constants from "../utils/Constants";
import { LinkServiceExpandableSection } from "../components/CommonComponents";
import HelperFunctions from "../common/HelperFunctions";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";
import LinkDetailTable from "./LinkDetailTable";

/**
 * This class is responsible for handling the state of the link hierarchy component which is used
 * on the search page and on the hierarchy tab of an individual link. This component is also responsible
 * for modifying the links in the hierarchical edit mode of the link detail page.
 */
export default class LinkHierarchy extends Component {
    state = {
        isExpanded: Object.assign(
            {},
            ...Object.keys(HelperFunctions.getLinkHierarchyOrder()).map(linkType =>
                ({ [linkType]: this.props.expanded || false }))
        ),
        linksInLinkTypeMap: this.props.linksInLinkTypeMap,
        updatedLinksInLinkTypeMap: {},
        modifiedLinkFields: {},
        inEditMode: false,
        submissionInProgress: false,
        checkForExpansion: new Map(),
        handleThirdPartyLinks: false
    }

    componentDidUpdate() {
        this.updateLinkContext();
    }

    getHeaderTextSuffix = linkType => (linkType in Constants.HEADER_SUFFIX ? ` ${Constants.HEADER_SUFFIX[linkType]}` : "");

    linkServiceBackendClient = new LinkServiceBackendClient();

    updateLinkContext = () => {
        if (Object.keys(HelperFunctions.getLinkHierarchyOrder()).filter(
            linkType =>
                !HelperFunctions.equalsIgnoreOrder(this.state.linksInLinkTypeMap[linkType],
                    this.props.linksInLinkTypeMap[linkType])
        ).length > 0) {
            this.setState({
                linksInLinkTypeMap: this.props.linksInLinkTypeMap
            });
        }
    }

    handleExpand = (evt) => {
        const isExpanded = HelperFunctions.deepClone(this.state.isExpanded);
        isExpanded[evt.target.id] = evt.detail.expanded;
        this.setState({
            isExpanded
        });
    }

    updateLinkEndField = (linkType, linkId, linkField, valueToAssign) => {
        const { updatedLinksInLinkTypeMap, modifiedLinkFields } = this.state;
        const link = updatedLinksInLinkTypeMap[linkType].find(l => l.instanceId === linkId);

        const aEndPort = linkField === Constants.ATTRIBUTES.aEndPort ? valueToAssign : link.aEndPort;
        const bEndPort = linkField === Constants.ATTRIBUTES.bEndPort ? valueToAssign : link.bEndPort;

        modifiedLinkFields[linkId] = modifiedLinkFields[linkId] || {};

        modifiedLinkFields[linkId][Constants.ATTRIBUTES.aEndPort] = aEndPort;
        modifiedLinkFields[linkId][Constants.ATTRIBUTES.bEndPort] = bEndPort;

        // Here we handle the Router To DWDM udpates that are necessary when a Router To Router fabric
        // port is updated. The updates are written to the `modifiedLinkFields` object here, and the
        // actual calling of the `updateLinkEnds` API happens in the `handleUpdatedLinkSubmission` method below.
        // The scenario where only a client port is updated (and not a fabric port), is
        // handled as any other field in the `handleLinkChange` method below.
        if (linkField === Constants.ATTRIBUTES.aEndPort) {
            modifiedLinkFields[linkId][Constants.ATTRIBUTES.aEndClientPort] = link.aEndClientPort;
        } else {
            modifiedLinkFields[linkId][Constants.ATTRIBUTES.bEndClientPort] = link.bEndClientPort;
        }

        link.aEndPort = aEndPort;
        link.bEndPort = bEndPort;
    }

    handleLinkChange = (evt, updatingAttributeField = false, selectedItems) => {
        const { updatedLinksInLinkTypeMap, modifiedLinkFields } = this.state;
        let valueToAssign;
        // We pull the link type, link ID, and link field from the ID based off of the separator
        const linkType = evt.target.id.split(Constants.SEPARATOR)[0];
        const linkId = evt.target.id.split(Constants.SEPARATOR)[1];
        const linkField = evt.target.id.split(Constants.SEPARATOR)[2];
        const attributeKey = evt.target.id.split(Constants.SEPARATOR)[3];
        const endInterfaceColumns = [Constants.ATTRIBUTES.aEndPort, Constants.ATTRIBUTES.bEndPort];
        const massUpdateFields = [Constants.ATTRIBUTES.lifecycleState, Constants.ATTRIBUTES.cm_or_tt_url];

        if (evt.detail.selectedOption) {
            valueToAssign = evt.detail.selectedOption.value;
        } else {
            valueToAssign = evt.detail.value;
        }

        if (
            (massUpdateFields.includes(linkField) || massUpdateFields.includes(attributeKey)) &&
            selectedItems.map(item => item.instanceId).includes(linkId)
        ) {
            this.handleMassLinkChange(linkType, linkField, attributeKey, valueToAssign, selectedItems);
            return;
        }

        // Here we update the link with the new value. Except for attributes, everything else is a first level field
        // We first update the updatedLinksInLinkTypeMap so that UI renders the updated value
        // We next add the updated fields to modifiedLinkFields map so that we can directly call linkservice to update
        // the fields present in this map
        if (endInterfaceColumns.includes(linkField)) {
            this.updateLinkEndField(linkType, linkId, linkField, valueToAssign);
        } else if (linkField !== Constants.ATTRIBUTES.attributesToDisplay && !updatingAttributeField) {
            updatedLinksInLinkTypeMap[linkType].find(link => link.instanceId === linkId)[linkField] = valueToAssign;
            if (modifiedLinkFields[linkId] === undefined) {
                Object.assign(modifiedLinkFields, { [linkId]: { [linkField]: valueToAssign, linkType } });
            } else {
                Object.assign(modifiedLinkFields[linkId], { [linkField]: valueToAssign });
            }
        } else {
            const link = updatedLinksInLinkTypeMap[linkType].find(l => l.instanceId === linkId);
            const attributesObj = link[linkField].find(attribute => attribute.key === attributeKey);

            if (attributesObj) {
                attributesObj.value = valueToAssign;
            } else {
                link[linkField].push({ key: attributeKey, value: valueToAssign });
            }

            // To handle case where we update attribute first
            if (modifiedLinkFields[linkId] === undefined) {
                Object.assign(modifiedLinkFields, {
                    [linkId]: {
                        linkType,
                        [Constants.ATTRIBUTES.attributesToDisplay]:
                            { [attributeKey]: valueToAssign }
                    }
                });
            } else if (modifiedLinkFields[linkId][Constants.ATTRIBUTES.attributesToDisplay] === undefined) {
                // To handle case where some other field was updated first and we don't want to overwrite the existing
                // map for linkId
                Object.assign(modifiedLinkFields[linkId], {
                    [Constants.ATTRIBUTES.attributesToDisplay]: { [attributeKey]: valueToAssign }
                });
            } else {
                // To handle case where more than one attribute got updated
                Object.assign(modifiedLinkFields[linkId][Constants.ATTRIBUTES.attributesToDisplay],
                    { [attributeKey]: valueToAssign });
            }
        }

        // We need updatedLinksInLinkType to render the edits and an edited object to make the backend calls
        this.setState({
            updatedLinksInLinkTypeMap,
            modifiedLinkFields
        });
    }

    handleMassLinkChange = (linkType, linkField, attributeKey, valueToAssign, selectedItems) => {
        const { updatedLinksInLinkTypeMap, modifiedLinkFields } = this.state;
        const selectedLinksIds = selectedItems.map(item => item.instanceId);
        const linkObjectClone = updatedLinksInLinkTypeMap[linkType].map(link => Object.assign({}, link));
        const modifiedLinkFieldsClone = HelperFunctions.deepClone(modifiedLinkFields);

        linkObjectClone
            .filter(link => selectedLinksIds.includes(link.instanceId))
            .forEach((link) => {
                if (attributeKey) {
                    const attributesObj = link[linkField].find(attribute => attribute.key === attributeKey);
                    if (attributesObj) {
                        attributesObj.value = valueToAssign;
                    } else {
                        link[linkField].push({ key: attributeKey, value: valueToAssign });
                    }
                    return;
                }
                // eslint-disable-next-line no-param-reassign
                link[linkField] = valueToAssign;
            });

        selectedLinksIds.forEach((id) => {
            const modifiedLinkRecord = modifiedLinkFieldsClone[id] || {};
            if (attributeKey) {
                const attributesObject = modifiedLinkRecord.attributesToDisplay || {};
                const newAttributesObject = Object.assign({}, attributesObject, { [attributeKey]: valueToAssign });

                modifiedLinkRecord.attributesToDisplay = newAttributesObject;
            } else {
                modifiedLinkRecord[linkField] = valueToAssign;
            }

            modifiedLinkRecord.linkType = linkType;
            modifiedLinkFieldsClone[id] = modifiedLinkRecord;
        });

        this.setState({
            updatedLinksInLinkTypeMap: Object.assign({}, updatedLinksInLinkTypeMap, { [linkType]: linkObjectClone }),
            modifiedLinkFields: modifiedLinkFieldsClone
        });
    }

    createAttributeObject = (type, value) => {
        if (type === Constants.ATTRIBUTES_TYPES.boolean) {
            return {
                type,
                booleanValue: value,
                integerValue: 0,
                stringValue: ""
            };
        }

        if (type === Constants.ATTRIBUTES_TYPES.integer) {
            return {
                type,
                booleanValue: false,
                integerValue: parseInt(value, 10),
                stringValue: ""
            };
        }

        return {
            type,
            booleanValue: false,
            integerValue: 0,
            stringValue: value
        };
    }

    createAttributesObject = (linkId, linkType, modifiedAttributes) => {
        const { linksInLinkTypeMap } = this.state;
        const originalAttributes = linksInLinkTypeMap[linkType].find(link => link.instanceId === linkId).attributes;

        const attributes = [];
        Object.keys(modifiedAttributes).forEach((attributeToUpdate) => {
            const value = modifiedAttributes[attributeToUpdate];
            const originalType = originalAttributes.find(attribute => attribute.key === attributeToUpdate)?.value?.type;

            attributes.push({
                key: attributeToUpdate,
                value: this.createAttributeObject(originalType || "String", value)
            });
        });

        return attributes;
    }

    handleUpdatedLinkSubmission = async () => {
        this.setState({ submissionInProgress: true });

        // for each link instance, check what attributes were updated and make corresponding backend calls
        const { modifiedLinkFields } = this.state;
        const endInterfaceColumns = [Constants.ATTRIBUTES.aEndPort, Constants.ATTRIBUTES.bEndPort];
        // eslint-disable-next-line no-restricted-syntax
        for (const linkId of Object.keys(modifiedLinkFields)) {
            if (endInterfaceColumns.some(col => modifiedLinkFields[linkId][col] !== undefined)) {
                // eslint-disable-next-line no-await-in-loop
                await this.linkServiceBackendClient.updateLinkEnds(
                    `${Constants.LINK_INSTANCE_ID_PATTERN}${linkId}`,
                    [
                        modifiedLinkFields[linkId][Constants.ATTRIBUTES.aEndPort],
                        modifiedLinkFields[linkId][Constants.ATTRIBUTES.bEndPort]
                    ]
                );
            }

            // Call the `UpdateLinkEnds` API for the Router To DWDM link ends. The Router To DWDM link
            // ends will be udpated either when a Router To Router fabric port is updated, or when a
            // client port is updated.
            if (modifiedLinkFields[linkId][Constants.ATTRIBUTES.aEndClientPort] !== undefined) {
                const link = this.state.updatedLinksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter]
                    .find(l => l.instanceId === linkId);

                // eslint-disable-next-line no-await-in-loop
                await this.linkServiceBackendClient.updateLinkEnds(
                    `${Constants.LINK_INSTANCE_ID_PATTERN}${link.aEndDwdmLinkId}`,
                    [link.aEndPort, link.aEndClientPort]
                );
            }

            if (modifiedLinkFields[linkId][Constants.ATTRIBUTES.bEndClientPort] !== undefined) {
                const link = this.state.updatedLinksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter]
                    .find(l => l.instanceId === linkId);

                // eslint-disable-next-line no-await-in-loop
                await this.linkServiceBackendClient.updateLinkEnds(
                    `${Constants.LINK_INSTANCE_ID_PATTERN}${link.bEndDwdmLinkId}`,
                    [link.bEndPort, link.bEndClientPort]
                );
            }

            if (modifiedLinkFields[linkId][Constants.ATTRIBUTES.lifecycleState] !== undefined) {
                // eslint-disable-next-line no-await-in-loop
                await this.linkServiceBackendClient.updateLifeCycle(
                    `${Constants.LINK_INSTANCE_ID_PATTERN}${linkId}`,
                    modifiedLinkFields[linkId][Constants.ATTRIBUTES.lifecycleState],
                    this.state.handleThirdPartyLinks
                );
            }

            if (modifiedLinkFields[linkId][Constants.ATTRIBUTES.encryptionCapability] !== undefined) {
                // eslint-disable-next-line no-await-in-loop
                await this.linkServiceBackendClient.updateEncryptionCapability(
                    `${Constants.LINK_INSTANCE_ID_PATTERN}${linkId}`,
                    modifiedLinkFields[linkId][Constants.ATTRIBUTES.encryptionCapability]
                );
            }

            if (modifiedLinkFields[linkId][Constants.ATTRIBUTES.encryptionIntent] !== undefined) {
                // eslint-disable-next-line no-await-in-loop
                await this.linkServiceBackendClient.updateEncryptionIntent(
                    `${Constants.LINK_INSTANCE_ID_PATTERN}${linkId}`,
                    modifiedLinkFields[linkId][Constants.ATTRIBUTES.encryptionIntent]
                );
            }

            if (modifiedLinkFields[linkId][Constants.ATTRIBUTES.attributesToDisplay] !== undefined) {
                const attributesToUpdate = this.createAttributesObject(
                    linkId,
                    modifiedLinkFields[linkId].linkType,
                    modifiedLinkFields[linkId][Constants.ATTRIBUTES.attributesToDisplay]
                );
                // eslint-disable-next-line no-await-in-loop
                await this.linkServiceBackendClient.updateAttributes(
                    `${Constants.LINK_INSTANCE_ID_PATTERN}${linkId}`,
                    attributesToUpdate
                );
            }
        }

        this.setState({ submissionInProgress: false, inEditMode: false });
        window.location.reload();
    }

    handleSubmit = async () => {
        try {
            await this.handleUpdatedLinkSubmission();
        } catch (e) {
            this.setState({ submissionInProgress: false, inEditMode: false });
            this.props.handleFlashbarMessageFromChildTabs(null, e);
        }
    }

    handleEditModeChange = () => {
        this.setState({
            updatedLinksInLinkTypeMap: HelperFunctions.deepClone(this.state.linksInLinkTypeMap),
            modifiedLinkFields: {},
            inEditMode: !this.state.inEditMode
        });
    }

    handleThirdPartyToggleChange = () => {
        this.setState({ handleThirdPartyLinks: !this.state.handleThirdPartyLinks });
    }

    // TODO can do this in edit mode
    generateUpdatedLinks = (links, selectedItems) => {
        links.forEach(link =>
            Object.assign(link, {
                handleLinkChange: (evt, updatingAttributeField) => {
                    this.handleLinkChange(evt, updatingAttributeField, selectedItems);
                },
                handleExpansionState: this.handleExpansionState,
                checkForExpansion: this.state.checkForExpansion
            }));

        return links;
    }

    handleExpansionState = (evt, uuid) => {
        if (evt.detail.expanded) {
            const { checkForExpansion } = this.state;
            checkForExpansion.set(uuid, true);
            this.setState({ checkForExpansion });
        } else {
            const { checkForExpansion } = this.state;
            checkForExpansion.set(uuid, false);
            this.setState({ checkForExpansion });
        }
    }

    generateLinksWithExpansionState = (links) => {
        links.forEach(link =>
            Object.assign(link, {
                handleExpansionState: this.handleExpansionState,
                checkForExpansion: this.state.checkForExpansion
            }));
        return links;
    }

    render() {
        const linkLayersToShow = Object.entries(HelperFunctions.getLinkHierarchyOrder(
            this.props.editable && this.state.inEditMode
        ))
            .filter(([linkType]) =>
                (
                    this.state.linksInLinkTypeMap[linkType] &&
                    this.state.linksInLinkTypeMap[linkType].length > 0 &&
                    // We do not want to show the Router To DWDM table in the UI since we are
                    // including the client port information in the Router To Router table.
                    // See https://sim.amazon.com/issues/FremontNEST-4544
                    linkType !== Constants.LINK_TYPES.routerToDWDM &&
                    // We do not want to show the PowermuxToPowermux table in the UI since we are
                    // unsure of what the customer issues are.
                    // See https://issues.amazon.com/issues/FremontNEST-4886
                    linkType !== Constants.LINK_TYPES.powerMuxToPowerMux
                )
                || this.props.showEmptyLinkLayerTable);

        if (this.props.isFirstTimeOnPage) {
            return <div/>;
        }

        if (this.props.tooManyLinksFound) {
            return <Alert> NARROW DOWN YOUR SEARCH (TOO MANY RECORDS) </Alert>;
        }

        if (linkLayersToShow.length === 0 && !this.props.showEmptyLinkLayerTable && !this.props.isSearchInProgress) {
            return <Alert> NO SEARCH RESULTS FOUND </Alert>;
        }
        const isDownloadEnabled = (linkType) => {
            const linkWithDownloadEnabled = [Constants.LINK_TYPES.routerToRouter,
                Constants.LINK_TYPES.trunkToTrunk, Constants.LINK_TYPES.muxToMux,
                Constants.LINK_TYPES.passiveToPassive, Constants.LINK_TYPES.clientToClient];
            return linkWithDownloadEnabled.some(link => link === linkType);
        };
        return (
            <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_BUTTON_PADDING}>
                <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                    {
                        linkLayersToShow
                            .map(([linkType, columnDefinitions]) => (
                                <LinkServiceExpandableSection
                                    headerText={linkType}
                                    variant="container"
                                    expanded={this.state.isExpanded[linkType]}
                                    key={linkType}
                                    id={linkType}
                                    onChange={this.handleExpand}
                                >
                                    <LinkDetailTable
                                        linkType={linkType}
                                        inEditMode={this.state.inEditMode}
                                        isDownloadEnabled={isDownloadEnabled(linkType)}
                                        isDeleteEnabled={this.props.isDeleteEnabled}
                                        entity={`${linkType}${this.getHeaderTextSuffix(linkType)}`}
                                        generateUpdatedLinks={this.generateUpdatedLinks}
                                        updatedLinksInLinkTypeMap={this.state.updatedLinksInLinkTypeMap}
                                        linksInLinkTypeMap={this.state.linksInLinkTypeMap}
                                        columnDefinitions={columnDefinitions}
                                        downloadableColumnDefinitions={
                                            HelperFunctions.getDownloadableLinkColumns(linkType)}
                                        editable={this.props.editable}
                                        handleEditModeChange={this.handleEditModeChange}
                                        editButtonDisabled={this.props.editButtonDisabled}
                                        submissionInProgress={this.state.submissionInProgress}
                                        handleSubmit={this.handleSubmit}
                                        handleFlashbarMessageFromChildTabs={
                                            this.props.handleFlashbarMessageFromChildTabs}
                                        handleFlashbarMessageFromSearchPage={
                                            this.props.handleFlashbarMessageFromSearchPage}
                                        updateLinkHierarchy={this.props.updateLinkHierarchy}
                                        generateLinksWithExpansionState={this.generateLinksWithExpansionState}
                                        updateTableItems={this.props.updateTableItems}
                                        isSearchPage={this.props.isSearchPage}
                                        handleThirdPartyLinks={this.state.handleThirdPartyLinks}
                                        handleThirdPartyToggleChange={this.handleThirdPartyToggleChange}
                                    />
                                </LinkServiceExpandableSection>
                            ))
                    }
                </SpaceBetween>
            </SpaceBetween>
        );
    }
}
import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Flashbar,
    FormField,
    Header,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import { FremontButton, FremontInput, FremontSelect, ComponentConstants, requiredLabel } from "utils/CommonComponents";
import FremontTable from "table/FremontTable";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const serviceTypeOptions = HelperFunctions.createSelectedOptions(Object.values(Constants.SERVICE_TYPES));
export const orderRegionOptions = HelperFunctions.createSelectedOptions(
    Object.values(Constants.ORDER_GEOGRAPHIC_REGION)
);

export const NewStageSlaModalForm = props => (
    <div>
        <Modal
            visible={props.newStageSlaModalVisible}
            header="Add a New Stage SLA"
            onDismiss={props.handleDismissNewStageSlaModal}
            size="large"
            footer={
                <Box variant="span" float="right">
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        <FremontButton
                            variant="normal"
                            onClick={props.handleDismissNewStageSlaModal}
                            disabled={props.isSubmissionInProgress}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id="newStageSla"
                            variant="primary"
                            onClick={props.handleSubmit}
                            loading={props.isSubmissionInProgress}
                        >
                            {Constants.SUBMISSION_STRINGS.readyToSubmit}
                        </FremontButton>
                    </SpaceBetween>
                </Box>
            }
        >
            <Flashbar
                items={props.flashbar.text ?
                    HelperFunctions.generateErrorMessageForFlashbar(props.flashbar.text) : []}
            />
            <ColumnLayout columns={2} borders="vertical">
                <FormField
                    label={requiredLabel("Service Type:")}
                    errorText={props.errorTexts[Constants.ATTRIBUTES.serviceType]}
                >
                    <FremontSelect
                        id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.serviceType}`}
                        options={serviceTypeOptions}
                        selectedOption={HelperFunctions.createSelectedOption(props.newStageSlaObject.serviceType)}
                        disabled={props.isSubmissionInProgress}
                        onChange={props.handleNewStageSlaInputChange}
                    />
                </FormField>
                <FormField
                    label={requiredLabel("Order Type:")}
                    errorText={props.errorTexts[Constants.ATTRIBUTES.orderType]}
                >
                    <FremontSelect
                        id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.orderType}`}
                        options={Constants.RESOURCE_ORDER_TYPES_ARRAY}
                        selectedOption={
                            HelperFunctions.createSelectedOption(props.newStageSlaObject.orderType)
                        }
                        onChange={props.handleNewStageSlaInputChange}
                        disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                    />
                </FormField>
                {props.newStageSlaObject.serviceType !== Constants.SERVICE_TYPES.BACKBONE &&
                    <FormField
                        label={requiredLabel("Order Geographic Region:")}
                        errorText={props.errorTexts[Constants.ATTRIBUTES.geographicRegion]}
                    >
                        <FremontSelect
                            id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.geographicRegion}`}
                            options={orderRegionOptions}
                            selectedOption={
                                HelperFunctions.createSelectedOption(props.newStageSlaObject.geographicRegion)}
                            disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                            onChange={props.handleNewStageSlaInputChange}
                        />
                    </FormField>
                }
                {props.newStageSlaObject.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
                <FormField
                    label={requiredLabel("Customer Fabric:")}
                    errorText={props.errorTexts[Constants.ATTRIBUTES.customerFabric]}
                >
                    <FremontSelect
                        id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.customerFabric}`}
                        options={HelperFunctions.createSelectedOptions(
                            Object.values(Constants.CUSTOMER_FABRICS)
                        )}
                        selectedOption={HelperFunctions.createSelectedOption(props.newStageSlaObject.customerFabric)}
                        disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                        onChange={props.handleNewStageSlaInputChange}
                    />
                </FormField>
                }
                <FormField
                    label={requiredLabel("Priority:")}
                    errorText={props.errorTexts[Constants.ATTRIBUTES.priorityType]}
                >
                    <FremontSelect
                        id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.priorityType}`}
                        options={HelperFunctions.createSelectedOptions(
                            Object.keys(Constants.ORDER_PRIORITY_OPTIONS)
                        )}
                        selectedOption={HelperFunctions.createSelectedOption(props.newStageSlaObject.priorityType)}
                        disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                        onChange={props.handleNewStageSlaInputChange}
                    />
                </FormField>
            </ColumnLayout>
        </Modal>
    </div>
);

export const StageSlasTablesCommonHeader = props => (
    <Header
        variant="h2"
        actions={
            props.editMode ?
                <div>
                    <SpaceBetween direction="horizontal" size="xs">
                        <FremontButton
                            id={props.id}
                            onClick={props.handleEditModeChange}
                            loading={props.isLoading}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id={props.id}
                            variant="primary"
                            onClick={props.handleSubmitUpdate}
                            loading={props.isLoading}
                        >
                            Submit
                        </FremontButton>
                    </SpaceBetween>
                </div>
                :
                <div>
                    <SpaceBetween direction="horizontal" size="xs">
                        <FremontButton
                            id={props.id}
                            iconName="edit"
                            variant="primary"
                            onClick={props.handleEditModeChange}
                            disabled={props.isDisabled}
                            className={props.id}
                        />
                    </SpaceBetween>
                </div>
        }
    >
        {props.title}
    </Header>
);

export const getStageCells = (editMode, stages, handleInputChange) => {
    const array = [{
        id: Constants.ATTRIBUTES.orderVariant,
        header: "Order Variant",
        width: 350,
        cell: item => (<div> {item.orderVariant.replaceAll("#", " - ")} </div>)
    }];
    stages.forEach(stage =>
        array.push(Object.assign({
            id: stage,
            header: stage,
            width: 165,
            cell: item => (!editMode ?
                <div> {item[Constants.ATTRIBUTES.stageMap][stage]} </div>
                :
                <FremontInput
                    id={`${item.stageSlaId}${Constants.SEPARATOR}${stage}`}
                    value={item[Constants.ATTRIBUTES.stageMap][stage]}
                    onInput={handleInputChange}
                    type={Constants.NUMBER}
                />
            )
        })));
    return array;
};

export const filterSlas = (stageSlas, serviceTypes, orderTypes) => {
    const filtered = stageSlas.filter(stageSla =>
        serviceTypes.includes(stageSla.orderVariant.split(Constants.POUND_SEPARATOR)[0]) &&
        orderTypes.includes(stageSla.orderVariant.split(Constants.POUND_SEPARATOR)[2]));

    return filtered;
};

export const GenericSlaVariant = props => (
    <div>
        <Container
            header={
                <StageSlasTablesCommonHeader
                    id={props.slaVariantType}
                    handleEditModeChange={props.handleEditModeChange}
                    handleSubmitUpdate={props.handleSubmit}
                    editMode={props.editMode}
                    title={props.slaVariantType}
                    isDisabled={props.isDisabled}
                    isLoading={props.isLoading}
                />
            }
        >
            <div>
                <FremontTable
                    entity={props.slaVariantType}
                    columnDefinitions={getStageCells(props.editMode, props.orderVariant, props.handleInputChange)}
                    loading={props.isLoading}
                    tableItems={props.editMode ? props.stageSlaObjectsEditMode : props.stageSlaObjects}
                    emptyTableMessage={Constants.EMPTY_TABLE_MESSAGES.noStageSlas}
                />
            </div>
        </Container>
    </div>
);

export const StageSlaForm = props => (
    <SpaceBetween direction="vertical" size="xs">
        <Header
            variant="h3"
            actions={
                <FremontButton
                    id={props.id}
                    variant="primary"
                    onClick={props.handleAddNewStageSlaModal}
                >
                    Create New Sla for Order Variant
                </FremontButton>
            }
        />
        {props.isUserManager &&
        <GenericSlaVariant
            editMode={props.editModeTable === Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_INSTALL_STAGES}
            handleEditModeChange={props.handleEditModeChange}
            handleSubmitUpdate={props.handleSubmitUpdate}
            isDisabled={props.isSubmissionInProgress ||
            (!!props.editModeTable
                && props.editModeTable !== Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_INSTALL_STAGES)}
            isLoading={props.isSubmissionInProgress}
            orderVariant={Constants.ORDER_TYPE_STAGE_MAP.SETTLEMENT_FREE_PEERING_INSTALL_CHANGE_STAGES}
            slaVariantType={Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_INSTALL_STAGES}
            stageSlaObjects={filterSlas(
                props.stageSlaObjects,
                Constants.INTERCONNECT_SERVICE_TYPES,
                [Constants.ORDER_TYPES.INSTALL, Constants.ORDER_TYPES.CHANGE]
            )}
            stageSlaObjectsEditMode={filterSlas(
                props.stageSlaObjectsEditMode,
                Constants.INTERCONNECT_SERVICE_TYPES,
                [Constants.ORDER_TYPES.INSTALL, Constants.ORDER_TYPES.CHANGE]
            )}
            handleInputChange={props.handleInputChange}
            handleSubmit={props.handleSubmit}
        />
        }
        {props.isUserManager &&
        <GenericSlaVariant
            editMode={props.editModeTable === Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_DECOM_STAGES}
            handleEditModeChange={props.handleEditModeChange}
            handleSubmitUpdate={props.handleSubmitUpdate}
            isDisabled={props.isSubmissionInProgress ||
            (!!props.editModeTable
                && props.editModeTable !== Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_DECOM_STAGES)}
            isLoading={props.isSubmissionInProgress}
            orderVariant={Constants.ORDER_TYPE_STAGE_MAP.SETTLEMENT_FREE_PEERING_DECOM_STAGES}
            slaVariantType={Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_DECOM_STAGES}
            stageSlaObjects={filterSlas(
                props.stageSlaObjects,
                Constants.INTERCONNECT_SERVICE_TYPES,
                [Constants.ORDER_TYPES.DECOMMISSION]
            )}
            stageSlaObjectsEditMode={filterSlas(
                props.stageSlaObjectsEditMode,
                Constants.INTERCONNECT_SERVICE_TYPES,
                [Constants.ORDER_TYPES.DECOMMISSION]
            )}
            handleInputChange={props.handleInputChange}
            handleSubmit={props.handleSubmit}
        />
        }
    </SpaceBetween>
);
import React from "react";
import { useHistory } from "react-router-dom";
import FremontRouter from "common/FremontRouter";
import LighthouseLogo from "lighthouseLogo.png";
import { FremontTopNavigation } from "../utils/CommonComponents";
import Constants from "../utils/Constants";

const noReloadNavigationHandler = (history, event) => {
    event.preventDefault();
    history.push(event.detail.href);
};

const LighthouseContent = (props) => {
    const { userId } = props.user;
    const history = useHistory();

    return (
        <div>
            <FremontTopNavigation
                i18nStrings={{
                    searchIconAriaLabel: "Search",
                    searchDismissIconAriaLabel: "Close search",
                    overflowMenuTriggerText: "More",
                    overflowMenuTitleText: "All",
                    overflowMenuBackIconAriaLabel: "Back",
                    overflowMenuDismissIconAriaLabel: "Close menu"
                }}
                identity={{
                    href: Constants.LIGHTHOUSE_ROUTES.lighthouseLandingPage,
                    title: "Lighthouse",
                    logo: {
                        src: LighthouseLogo,
                        alt: "Lighthouse"
                    }
                    // identity does not have the onClick handler and the on follow handler still forces a full reload.
                    // May be able to solve this with data attributes
                    // https://cloudscape.aws.dev/components/top-navigation/?tabId=api
                }}
                utilities={[
                    {
                        type: "button",
                        text: "Fremont",
                        href: Constants.ROUTES.fremontDashboard,
                        external: false,
                        onClick: event => noReloadNavigationHandler(history, event)
                    },
                    {
                        type: "button",
                        text: "LinkService",
                        href: Constants.MANGO_ROUTES.linkServiceDashboard,
                        external: false,
                        onClick: event => noReloadNavigationHandler(history, event)
                    },
                    {
                        type: "button",
                        text: "Halo",
                        href: Constants.LIGHTHOUSE_PREPENDED_PATHS.halo,
                        external: false,
                        onClick: event => noReloadNavigationHandler(history, event)
                    },
                    {
                        type: "button",
                        text: "Insights",
                        // TODO this will need to change to the insights page in the future
                        href: Constants.LIGHTHOUSE_ROUTES.reportFiber,
                        external: false,
                        onClick: event => noReloadNavigationHandler(history, event)
                    },
                    {
                        type: "menu-dropdown",
                        text: `Welcome ${userId}!`,
                        iconName: "user-profile",
                        items: [
                            {
                                id: "ticket",
                                text: "Bug Report",
                                // TODO create a lighthouse folder and bug ticket
                                href: "https://t.corp.amazon.com/create/templates/792521b2-a12e-4e0e-a5e6-fb38e3340d5b",
                                external: true,
                                externalIconAriaLabel: "(opens in new tab)"
                            }
                        ]
                    }
                ]}
            />
            <FremontRouter
                {...props}
            />
        </div>
    );
};

export default LighthouseContent;
